import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite nicht gefunden" />
    <Content>
      <h1>Seite nicht gefunden.</h1>
      <p>
        Diese Seite existiert leider nicht. <Link to="/">› Startseite</Link>
      </p>
      <br />
      <br />
      <br />
    </Content>
  </Layout>
)

export default NotFoundPage
